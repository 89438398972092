import React from 'react';
import { Link } from 'gatsby';

const ContentLink = ({ link, type, openLinkInNewTab, children }) => {
  if (!link) return children;

  if (type === 'FILE') {
    return (
      <a href={link} draggable="false">
        {children}
      </a>
    );
  }

  if (type === 'EXTERNAL' || openLinkInNewTab) {
    return (
      <a target={openLinkInNewTab ? '_blank' : '_self'} href={link} rel="noopener noreferrer" draggable="false">
        {children}
      </a>
    );
  }

  return (
    <Link to={link} draggable="false">
      {children}
    </Link>
  );
};

export default ContentLink;
