import React from 'react';

import { constructLink } from '../../../../helper';
import Image from '../../../Image';
import Carousel from '../../../Carousel';
import ContentLink from '../../../ContentLink';

import styles from './styles.module.css';

class CarouselLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    let crop = true;
    if (props.section && props.section.styles && props.section.styles.crop === false) ({ crop } = props.section.styles);

    this.state = {
      section: props.section,
      crop,
    };
  }

  render() {
    const colorBlack = {
      color: '#000000',
    };

    const cards = [];

    this.state.section.data.map((item, index) => {
      if (item.type === 'IMAGES/IMAGE') {
        const sizes = '100vw';
        const align = item.content.align || 'Left';

        let card = (
          <div key={`${this.state.section._id}_${item.content.id}_${index}`} className={`${styles.carouselWrapper}`}>
            <div className={`${styles.carouselImageWrapper}`}>
              <Image
                id={item.content.id}
                url={item.content.src}
                alt={item.content.alt}
                sizes={sizes}
                imageStyleName={`carouselImg${this.state.crop === false ? 'NoCrop' : ''}`}
                images={this.props.images}
              />
              {(this.state.section.styles.alt === true || this.state.section.styles.description === true) &&
                (item.content.alt || item.content.description) && (
                  <div className={styles[`imageTextWrapper${align}`]} style={this.props.boxStyle}>
                    {this.state.section.styles.alt === true && (
                      <div className={`${styles.imageAlt}`}>
                        <h2 style={colorBlack}>{item.content.alt}</h2>
                      </div>
                    )}
                    {this.state.section.styles.description === true && (
                      <div className={`${styles.imageText}`}>
                        <span style={colorBlack}>{item.content.description}</span>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        );

        if (item.content.linkObj && (item.content.linkObj.src || item.content.linkObj.type)) {
          const key = `${this.props.section._id}_CarouselLink_${index}`;
          const { link, type: linkType } = constructLink(
            item.content.linkObj.type ? item.content.linkObj : item.content.linkObj.src,
            this.props.pagePathList,
            this.props.articlePathList,
            this.props.filePathList,
          );

          card = (
            <ContentLink key={key} openLinkInNewTab={item.content.linkObj.openLinkInNewTab} link={link} type={linkType}>
              {card}
            </ContentLink>
          );
        }

        cards.push(card);
      }

      return null;
    });

    return (
      <div className={`${this.props.full ? '' : 'container'} ${styles[`carouselContainer${this.props.full}`]}`}>
        <div className={`row ${styles.noMargin}`}>
          <div className={`col-12 ${styles.carouselCol}`}>
            <Carousel
              showArrows={this.state.section.styles.arrows}
              showIndicators={this.state.section.styles.indicators}
              autoPlay={this.state.section.styles.autoplay}
              infiniteLoop={this.state.section.styles.infinite}
              interval={this.state.section.styles.interval}
            >
              {cards}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselLayout;
