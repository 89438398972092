/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import { formColor } from '../../helper';

import styles from './styles.module.css';

const fontSizeSwitch = (size) => {
  switch (size) {
    case 'Small':
      return '0.85rem';
    case 'Normal':
      return '1rem';
    case 'Large':
      return '1.25rem';
    default:
      return '1rem';
  }
};

const CustomizableButton = ({ className, data, themeData, button, invert, label, fullWidth, ...rest }) => {
  let bColor;
  let border;
  let borderRadius = `${themeData.button.radius}px`;
  let boxShadow;
  let fontColor;
  let bgColor;

  if (data && data.styles) {
    let bColorHex = themeData.colors[2];
    const defaultThickness = button.includes('Secondary') ? '1px' : 0;
    let bThickness = defaultThickness;

    if (data.styles.border && data.styles.border.active) {
      if (data.styles.border.color !== '') bColorHex = data.styles.border.color;
      bThickness = `${data.styles.border.thickness}px`;
      borderRadius = `${data.styles.border.radius}px`;
    }

    bColor = formColor(
      { solid: bColorHex },
      false,
      data.styles.border ? data.styles.border.opacity : 1,
      undefined,
      themeData.colors,
      invert,
    );

    border = `${bColor.backgroundColor} solid ${bThickness}`;

    if (data.styles.shadow && data.styles.shadow.active) {
      const { x, y, blur, spread } = data.styles.shadow;

      let shadow = data.styles.shadow.color;
      if (data.styles.shadow.color === '') {
        [, , shadow] = themeData.colors;
      }

      const sColor = formColor(
        { solid: shadow },
        false,
        data.styles.shadow ? data.styles.shadow.opacity : 1,
        undefined,
        themeData.colors,
        invert,
      );

      boxShadow = `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`;
    }

    let font = button.includes('Secondary') ? themeData.colors[0] : '#FFF';
    if (data.styles.fontColor && data.styles.fontColor.color) font = data.styles.fontColor.color;

    fontColor = formColor(
      { solid: font },
      undefined,
      data.styles.fontColor ? data.styles.fontColor.opacity : 1,
      undefined,
      themeData.colors,
      invert,
    );

    let backgroundColor = button.includes('Secondary') ? { solid: '#FFF' } : { solid: themeData.colors[0] };
    if (data.styles.backgroundColor && (data.styles.backgroundColor.solid || data.styles.backgroundColor.gradient.from))
      ({ backgroundColor } = data.styles);

    bgColor = formColor(
      backgroundColor,
      false,
      data.styles.backgroundColor ? data.styles.backgroundColor.opacity : 1,
      undefined,
      themeData.colors,
      invert,
    );
  }

  if (fullWidth) borderRadius = '';
  const fontFamily = themeData.typography.button.name;
  const fontSize = fontSizeSwitch(themeData.typography.button.fontSize);
  const { textTransform } = themeData.typography.button;
  const fontWeight = themeData.typography.button.weight;

  const style = {
    borderRadius,
    border,
    boxShadow,
    fontFamily,
    fontSize,
    textTransform,
    fontWeight,
    color: fontColor ? fontColor.backgroundColor : undefined,
  };

  if (bgColor) {
    if (bgColor.background) style.background = bgColor.background;
    else style.backgroundColor = bgColor.backgroundColor;
  }

  let icon;
  if (data && data.icon && data.icon.active === true) {
    style.paddingLeft = '14px';
    let color = data.icon.color || '#000000';
    if (color) {
      color = formColor({ solid: color }, false, 1, undefined, themeData.colors, invert).backgroundColor;
    }

    icon = (
      <span
        className={`entypo ${data.icon.class}`}
        aria-hidden
        style={{
          marginRight: label ? '16px' : '0',
          fontSize: data.icon.size,
          color,
        }}
      />
    );
  }

  return (
    <button className={clsx(styles.btn, fullWidth && styles.full, className)} style={style} {...rest}>
      {icon}
      {HTMLParser(label)}
    </button>
  );
};

export default CustomizableButton;
