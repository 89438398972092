import React from 'react';
import Cookies from 'universal-cookie';

import styles from './styles.module.css';

const cookies = new Cookies();

const CookieWarning = ({ colors, cookie }) => {

  const handleClick = () => {

    const d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    cookies.set('cookiesAccepted', true, { path: '/', expires: d });
    const wrapper = document.getElementById('CookieWarningWrapper');

    wrapper.style.display = 'none';

    if (cookie.layout === 'modal') {

      const warning = document.getElementById('CookieWarningModalWrapper');
      warning.style.display = 'none';

    }

  };

  let titleColor;
  let textColor;
  let bgColor;
  let btnBgColor;
  let btnBorderColor;
  let btnColor;

  if (cookie.colour === 'light') {

    [titleColor] = colors;
    [, , , textColor] = colors;
    [, bgColor] = colors;
    [btnBgColor] = colors;
    [btnBorderColor] = colors;
    [, btnColor] = colors;

  } else {

    [titleColor] = colors;
    [, textColor] = colors;
    [, , , bgColor] = colors;
    [, , , btnBgColor] = colors;
    [btnBorderColor] = colors;
    btnColor = '#fff';

  }

  const warning = (
    <div style={{ backgroundColor: bgColor }} id="CookieWarningWrapper" className={styles[cookie.layout || 'bottom']}>
      <div className={styles.container}>
        <div className="container">
          <div className="row">
            {
              cookie.cookieTitle.active
              && (
                <div className={`col-12 ${styles.elementWrapper}`}>
                  <h3 style={{ color: titleColor }}>
                    {cookie.cookieTitle.text}
                  </h3>
                </div>
              )
            }
            <div className={`${cookie.layout === 'modal' ? 'col-12' : 'col-12 col-lg-9'} ${styles.elementWrapper}`}>
              <span
                style={{ color: textColor }}
                dangerouslySetInnerHTML={{ __html: `${cookie.cookieText}` }}
              />
            </div>
            <div className={`${cookie.layout === 'modal' ? 'col-12' : 'col-12 col-lg-3'} ${styles.btnWrapper}`}>
              <button
                className={styles.btn}
                style={{ color: btnColor, backgroundColor: btnBgColor, border: `2px solid ${btnBorderColor}` }}
                onClick={handleClick}
              >
                {cookie.cookieButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let result;
  if (cookie.layout === 'modal') {

    result = (
      <div id="CookieWarningModalWrapper" className={`${styles[`warningBackground${cookie.colour}`]}`}>
        {warning}
      </div>
    );

  } else {

    result = warning;

  }

  return result;

};

export default CookieWarning;
