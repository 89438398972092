import React from 'react';

import styles from './styles.module.css';

const lang = require('../../lang.json');

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moving: '',
      selected: 0,
      selectedBefore: 0,
      timer: null,
      startPos: undefined,
    };
  }

  componentDidMount() {
    if (this.props.autoPlay) this.startTimer(this.props.interval);
  }

  componentWillUnmount() {
    if (this.state.timer) clearInterval(this.state.timer);
  }

  selectNext = (e, direction) => {
    if (e) e.stopPropagation();
    const selected =
      direction === 'R'
        ? (this.state.selected + 1) % this.props.children.length
        : this.state.selected < 1
        ? this.props.children.length - 1
        : this.state.selected - 1;

    if (this.props.autoPlay && !this.props.infiniteLoop && selected === this.props.children.length - 1)
      clearInterval(this.state.timer);

    this.setState({
      moving: direction,
      selected,
      selectedBefore: this.state.selected,
    });
  };

  handleTouch = (e, start) => {
    if (e) {
      e.stopPropagation();
      const [client] = e.changedTouches;
      if (start && client) this.setState({ startPos: client.clientX });
      else if (client && this.state.startPos !== undefined) {
        const direction = this.state.startPos > client.clientX ? 'R' : 'L';
        this.selectNext(undefined, direction);
        this.setState({ startPos: undefined });
      }
    }
  };

  startTimer(interval) {
    if (this.state.timer) clearInterval(this.state.timer);
    const timer = setInterval(() => this.selectNext(undefined, 'R'), interval);
    this.setState({
      timer,
    });
  }

  handleIndicatorClick(i) {
    this.setState({
      selected: i,
      moving: i < this.state.selected ? 'L' : i > this.state.selected ? 'R' : '',
      selectedBefore: this.state.selected,
    });
  }

  render() {
    const { moving, selected } = this.state;
    const childLength = this.props.children.length;
    let selectedItem = this.props.children[selected];

    const prevItemStyle = styles[`prevCarouselItem${moving}`];
    const selectedItemStyle = styles[`selectedCarouselItem${moving}`];
    const nextItemStyle = styles[`nextCarouselItem${moving}`];
    const preloadItemStyle = styles.nextCarouselItem;

    let preloadItemIndex = moving === 'L' ? selected - 1 : selected + 1;
    if (preloadItemIndex >= this.props.children.length) preloadItemIndex = 0;
    else if (preloadItemIndex < 0) preloadItemIndex = this.props.children.length - 1;
    const preloadItem = this.props.children[preloadItemIndex];

    const indicators = this.props.children.map((c, i) => (
      <div
        className={this.state.selected === i ? styles.carouselIndicatorSelected : styles.carouselIndicator}
        style={this.state.selected === i && this.props.customColor ? this.props.customColor : null}
        key={`carousel-indicator-${i}`}
        onClick={() => this.handleIndicatorClick(i)}
      />
    ));

    const textBtns = [];
    if (this.props.textnav) {
      ['previous', 'next'].forEach((a, i) => {
        const inactive = (selected === 0 && i === 0) || (selected === childLength - 1 && i === 1);
        const item = (
          <button
            className={styles[`carouselText${inactive ? 'Inactive' : ''}`]}
            key={`carousel-indicator-${a}`}
            onClick={(e) => this.selectNext(e, i === 0 ? 'L' : 'R')}
            style={i === 0 ? { marginRight: '10px' } : { marginLeft: '10px' }}
            disabled={inactive}
          >
            {lang[this.props.l][a]}
          </button>
        );

        if (!this.props.matches) textBtns.push(item);
        else if (i === 0) indicators.splice(0, 0, item);
        else indicators.push(item);
      });
    }

    let arrows;
    if (this.props.arrowsPos && this.props.arrowsPos !== 'Sides' && this.props.arrowSize !== undefined) {
      arrows = (
        <div className={styles[`arrowsContainer${this.props.arrowsPos}`]}>
          <button
            className={styles[`smallArrowContainerPrev${this.props.arrowSize}`]}
            onClick={(e) => this.selectNext(e, 'L')}
          >
            <i className={`entypo icon-left-open ${styles[`icon${this.props.arrowSize}`]}`} />
          </button>
          <button
            className={styles[`smallArrowContainer${this.props.arrowSize}`]}
            onClick={(e) => this.selectNext(e, 'R')}
          >
            <i className={`entypo icon-right-open ${styles[`icon${this.props.arrowSize}`]}`} />
          </button>
        </div>
      );
    }

    let leftArrow;
    let rightArrow;

    if (this.props.arrowsPos === 'Sides') {
      selectedItem = (
        <div className={styles.multipleWrapper}>
          <div className={styles.multipleImage}>
            {this.props.children[this.state.moving === 'L' ? preloadItemIndex : selected]}
          </div>
          <div className={styles.multipleImage}>
            {this.props.children[this.state.moving === 'L' ? selected : preloadItemIndex]}
          </div>
        </div>
      );
    }

    if (this.props.showArrows || this.props.arrowsPos === 'Sides') {
      let containerPrev =
        !this.props.infiniteLoop && selected === 0 ? 'prevArrowContainerHidden' : 'prevArrowContainer';
      let containerNext =
        !this.props.infiniteLoop && selected === childLength - 1 ? 'nextArrowContainerHidden' : 'nextArrowContainer';
      let prevArrow = <div className={styles.prevArrow} />;
      let nextArrow = <div className={styles.nextArrow} />;

      if (this.props.arrowsPos === 'Sides') {
        containerPrev = 'sidesPrevContainer';
        containerNext = 'sidesNextContainer';
        prevArrow = <i className={`entypo icon-left-open ${styles.icon}`} />;
        nextArrow = <i className={`entypo icon-right-open ${styles.icon}`} />;
      }

      leftArrow = (
        <button className={styles[containerPrev]} onClick={(e) => this.selectNext(e, 'L')}>
          {prevArrow}
        </button>
      );

      rightArrow = (
        <button className={styles[containerNext]} onClick={(e) => this.selectNext(e, 'R')}>
          {nextArrow}
        </button>
      );
    }

    let style;
    let containerStyle = this.props.mode === 'cards' ? styles.carouselContainerCards : styles.carouselContainer;
    if (this.props.arrowsPos === 'Sides') containerStyle = styles.carouselContainerSides;
    if (this.props.shadow) style = { boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)' };

    return (
      <div className={containerStyle} style={style}>
        {arrows}
        {leftArrow}
        {this.state.moving && this.props.arrowsPos !== 'Sides' && (
          <div
            className={this.state.moving === 'L' ? nextItemStyle : prevItemStyle}
            key={`oItem-${moving}-${selected}`}
          >
            {this.props.children[this.state.selectedBefore]}
          </div>
        )}
        <div
          className={selectedItemStyle}
          key={`sItem-${moving}-${selected}`}
          onTouchStart={(e) => this.handleTouch(e, true)}
          onTouchEnd={(e) => this.handleTouch(e, false)}
        >
          {selectedItem}
        </div>
        {this.props.arrowsPos !== 'Sides' && (
          <div className={preloadItemStyle} key={`ssItem-${moving}-${selected}`}>
            {preloadItem}
          </div>
        )}
        {rightArrow}
        {this.props.showIndicators && (
          <div className={styles.carouselIndicatorContainer} style={this.props.customIndicatorPosition}>
            {indicators}
            {textBtns.length > 0 && <div className={styles.btnsContainer}>{textBtns}</div>}
          </div>
        )}
      </div>
    );
  }
}

export default Carousel;
