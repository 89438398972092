/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import clsx from 'clsx';

import brokenImage from '../../images/broken.svg';

import styles from './styles.module.css';
import helper from './helper';

const Image = ({
  className,
  imageStyleName,
  images,
  id,
  url,
  alt,
  style = {},
  transform,
  position,
  opacity,
  size,
  sizes,
  dataIndex,
  onClick,
}) => {
  const imageId = useMemo(
    () =>
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 12),
    [],
  );

  // TODO: finish preloading implementation
  /*
  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {

      const preloadImage = (element) => {
        if (element.dataset && element.dataset.imgsrc) element.src = element.dataset.imgsrc;
        if (element.dataset && element.dataset.srcset) element.srcset = element.dataset.srcset;

        if (element.className) {
          // element.className = ` ${styles.noBlurImage} ${styles[props.imageClass]}`;
          element.className = styles[imageClass];
        }
      };

      const config = {
        rootMargin: '50px 0px',
        threshold: 0,
      };

      const imageObserver = new IntersectionObserver(function (entries, self) {
        entries.forEach((entry) => {
          let intersected = false;

          if (entry.isIntersecting) {
            intersected = true;
            preloadImage(entry.target);
          }

          if (intersected) {
            // using disconnect to destroy the intersectionObserver, which is only for one image
            self.disconnect();
          }
        });
      }, config);

      const img = document.getElementById(imageId);
      if (img) imageObserver.observe(img);
    }
  }, [imageClass, imageId]);
  */

  let image = helper.getImageById(id, images);
  let frmt = 'sm';

  if (!image && !id && url) {
    image = {
      path: url,
      alt: alt || 'static',
    };
    frmt = '';
  }

  style = {
    ...style,
    ...{ transform: transform ? `rotate(${transform.rotation || 0}deg)` : undefined },
    objectPosition:
      image && !!image.focusX && !!image.focusY ? `${image.focusX * 100}% ${image.focusY * 100}%` : position,
    objectFit: size,
    opacity,
  };

  const srcset = helper.getSrcSet(image);

  if (image && image.sizes && image.sizes.includes('og')) frmt = 'og';
  else if (frmt) {
    frmt = image && image.sizes && image.sizes.includes('me') ? 'me' : 'sm';
    if (image && image.sizes && !image.sizes.includes(frmt) && image.sizes.length > 0) [frmt] = image.sizes;

    // Check if browser supports src and sizes W
    if (typeof document !== 'undefined' && document) {
      const img = document.createElement('img');

      if ('sizes' in img) {
        frmt = image && image.sizes && image.sizes.includes('th') ? 'th' : 'sm';
        // sizes and srcset is suppored so use the lowest quality here for blur
      }
    }
  }

  let path = null;
  if (image && image.path) path = image.path;

  const imgsrc = helper.getImgSrc(path, frmt);
  const lastSlash = imgsrc.lastIndexOf('/');

  // let className = styles.blurImage;  // TODO disabled

  // random alt if not set for better SEO. Actual alt text would be much better though.
  const resolvedAlt =
    image && image.meta && (image.meta.imgTitle || image.meta.imgDesc)
      ? image.meta.imgTitle || image.meta.imgDesc
      : alt || imgsrc.substring(lastSlash + 1);

  if (srcset && srcset.length > 0) {
    return (
      <img
        draggable="false"
        loading="eager"
        srcSet={srcset}
        sizes={sizes || '100vw'}
        src={imgsrc}
        alt={resolvedAlt}
        className={clsx(className, styles[imageStyleName])}
        onClick={onClick}
        style={style}
        data-imageid={id}
        data-imgsrc={imgsrc}
        data-index={dataIndex}
        id={imageId}
      />
    );
  }

  return (
    <img
      draggable="false"
      src={imgsrc && imgsrc.length > 1 ? imgsrc : brokenImage}
      alt={resolvedAlt}
      className={clsx(className, styles[imageStyleName])}
      onClick={onClick}
      style={style}
      data-imageid={id}
      data-imgsrc={imgsrc}
      data-index={dataIndex}
      id={imageId}
    />
  );
};

export default Image;
